<template>
    <div class="LoginBox">
      <div class="content BoxConter">
        <p class="title">化学实验室预约服务平台</p>
        <!-- <div class="TitleLogin">
            <img src="./../../assets/img/TitleLogin.png" alt="" >
        </div> -->
        <div class="InputBox">
            <van-cell-group :border='false'>
                <van-field
                    :border='false'
                    size='small'
                    v-model="LoginData.phone"
                    left-icon="user-o"
                    class='LoginLabel'
                    clearable
                    maxlength="11"
                    placeholder="请输入手机号"/>
                <van-field
                    v-if="IsPawd"
                   :border='false'
                    v-model="LoginData.password"
                    maxlength="20"
                    tag='divs'
                    left-icon='fire-o'
                    class-prefix='11'
                    class='LoginLabel'
                    type="password"
                    size='small'
                    right-icon='eye-o'
                    @click-right-icon='IsPawd = !IsPawd'
                    placeholder="请输入登录密码"></van-field>
                <van-field
                    v-else
                   :border='false'
                    v-model="LoginData.password"
                    maxlength="20"
                    left-icon='fire-o'
                    class-prefix='11'
                    class='LoginLabel'
                    size='small'
                    right-icon='closed-eye'
                    @click-right-icon='IsPawd = !IsPawd'
                    placeholder="请输入登录密码"></van-field>
            </van-cell-group>
            <div class="Tips">{{Tips}}</div>
            <van-button type="primary" @click="LoginSumbit" :disabled='IsLoading' :loading='IsLoading' loading-type="spinner"   loading-text="登 录 中...">{{LoginText}}</van-button>
            <div class="TuoBox">
                <router-link class="heSecret" tag='span' to='/updatePas'>忘记密码</router-link>
            </div>
        </div>
        
      </div>
    </div>
</template>
<script>
import { Cell, CellGroup , Field , Button } from 'vant';
import {Api_Login_YyPasswordLogin} from '@/api/login'
import {Encryption} from '@/utils/validate'
export default {
     components: {
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [Field.name]: Field,
        [Button.name]: Button
    },
    data () {
        return {
            LoginData:{
                phone:'',
                password:''
            },
            IsPawd:true,
            Tips:'',
            toast:'',
            LoginText:"登　录",
            IsLoading:false
        }
    },
    methods:{
        LoginSumbit(){
            if(!this.LoginData.phone){
                this.Tips = '手机号不能空'
                return
            }
            if(!/^1[3|4|5|6|7|8|9][0-9]{9}$/.test(this.LoginData.phone)){
            this.Tips = '请输入正确手机号'
                return
            }
            if(!this.LoginData.password){
                this.Tips = '密码不能空'
                return
            }
             this.Tips = ''
            Api_Login_YyPasswordLogin({
                phone:this.LoginData.phone,
                password:Encryption(this.LoginData.password)
            }).then(res=>{
                localStorage.setItem('Token',res.Token)
                this.$router.push('/home')
            }).catch(err=>{
                this.Tips = err.msg
            })
        },
    }
}
</script>

<style lang="scss" scoped>

.LoginBox{
    display:flex;
    display: -webkit-flex; 
    flex-direction: column;
    -webkit-flex-direction:column;
    min-height:100vh;
    background:#ffffff; 
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); 
    .content{
        flex: 1;
        background: url('./../../assets/Image/LoginBaner.jpg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: 0 bottom;
    }
    .BoxConter {
        .TitleLogin {
            text-align: center;
            padding-top: 1.5rem;
            padding-bottom: 1.84rem;
            img {
                width: 71%;
            }
        }
        .InputBox {
            width: 79%;
            margin: 0 auto;
            .van-cell--borderless {
                border-bottom: 1px solid #e6e6e6;
            }
            /deep/ .van-field__left-icon, /deep/ .van-field__right-icon {
                margin-right: 12px;
                text-align: center;
                .van-icon {
                    font-size: 20px;
                    color: #adadad;
                }
            }
        }
        /deep/ .LoginLabel {
            .van-field__control {
                font-size: 14px;
                // font-family: '楷体';
            
            }
        }
        .Tips {
            padding-top: 0.3rem;
            font-size: 12px;
            color: #cb3029;
            height: 15px;
            line-height: 15px;
        }
        .van-button--primary {
            width: 100%;
            height: 38px;
            line-height: 38px;
            font-size: 15px;
            border-color: transparent;
            background: -webkit-linear-gradient(left, #87dfff, #77beef); /* Safari 5.1 - 6.0 */
            background: -o-linear-gradient(right, #87dfff, #77beef); /* Opera 11.1 - 12.0 */
            background: -moz-linear-gradient(right, #87dfff, #77beef); /* Firefox 3.6 - 15 */
            background: linear-gradient(to right, #87dfff , #77beef); /* 标准的语法 */
            border-radius: 25px;
        }
    }
    .title {
        margin-bottom: 1rem;
        font-size: 18px;
        text-align: center;
        color: #323233;
        padding: 0.8rem 0;
        font-weight: bold;
        padding-top: 2.3rem;
        // line-height: 24Px;
    }
    .foot {
        font-size: 12px;
        text-align: center;
        color: #323233;
        padding: 0.1rem 0;
    }
    .TuoBox {
        text-align: right;
        margin-right: 12px;
        padding-top: 8px;
        .heSecret {
            font-size: 14px;
            color:#1989fa ;
            letter-spacing: 1PX;
            text-decoration: underline;
        }
    }
   

}
</style>